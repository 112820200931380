import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import s from './SliderThemen.module.css'
import {Link} from 'gatsby'
import {StaticQuery, graphql} from 'gatsby'
import ButtonZurueck from '../ButtonZurueck';
import LogoSchauHin from '../LogoSchauHin';

function SampleNextArrow(props) {
    const {  onClick } = props;
    return (
      <div
      className={s.nextArrow}
        style={{ backgroundImage: `url(${props.arrowRight}) `,backgroundRepeat:'no-repeat' }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const {  onClick } = props;
    return (
      <div
      className={s.prevArrow}
        style={{ backgroundImage: `url(${props.arrowLeft}) `,backgroundRepeat:'no-repeat'}}
        onClick={onClick}
      />
    );
  }
  
export default class Responsive extends Component {

    constructor(props) {
      super(props);

      this.defaultBackground = "#FFD11B";
      this.mobileSize = 468;

      this.state = {
          currentBackground: this.defaultBackground
      };

      this.shouldChangeOrientation = true;
      this.currentSlideIndex = 1;

      this.handleResize = this.handleResize.bind(this);
  }

  setSliderOptions() {

    if(typeof window !== 'undefined') {
    
        let proportion = window.innerWidth/window.innerHeight;
        if(proportion>=280/653){
          this.isMobile = true;
          this.slidesToShow = 1.7;
          this.slidesToScroll =  1;
          this.initialSlide = 0;
          this.infinite = true;
          this.swipeToSlide = false;
          this.proprtionType=1;
        }
        if(proportion>=375/812){
          this.isMobile = true;
          this.slidesToShow = 1.7;
          this.slidesToScroll =  1;
          this.initialSlide = 0;
          this.infinite = true;
          this.swipeToSlide = false;
          this.proprtionType=2;
        }
        if(proportion>=207/448){
          this.isMobile = true;
          this.slidesToShow = 1.7;
          this.slidesToScroll =  1;
          this.initialSlide = 0;
          this.infinite = true;
          this.swipeToSlide = false;
          this.proprtionType=3;
        }
        if(proportion>=1024/1366){
          this.isMobile = true;
          this.slidesToShow =  2.3;
          this.slidesToScroll =  1;
          this.initialSlide = 0;
          this.infinite = true;
          this.swipeToSlide = false;
          this.proprtionType=4;
        }
        if(proportion>=4/3) {
          this.isMobile = false;
          this.slidesToShow =  3;
          this.slidesToScroll =  1;
          this.initialSlide = 0;
          this.infinite = true;
          this.swipeToSlide = true;
          this.proprtionType=5;
        }

        if(proportion>=16/9){
          this.isMobile = false;
          this.slidesToShow =  4;
          this.slidesToScroll =  1;
          this.initialSlide = 0;
          this.infinite = true;
          this.swipeToSlide = true;
          this.proprtionType=6;
        }

    }


  }

  componentWillMount() {
    this.setSliderOptions();
    this.forceUpdate();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setIsMobile() {
    let wasProportionType = this.proprtionType;
    this.setSliderOptions();
    //not mobile
    if(wasProportionType!==this.proprtionType) {
      this.shouldChangeOrientation = true;
      this.forceUpdate();
    }
  }

  handleResize(event) {
    this.setIsMobile();
  }

    render() {
           
      return(

        <StaticQuery
        query={graphql`
        query MyQuery {
        arrowLeft: file(relativePath: { eq: "arrowleft.svg" }){
          publicURL
           } ,
           arrowRight: file(relativePath: { eq: "arrowright.svg" }){
          publicURL
           } ,
           logoSchauHin: file(relativePath: { eq: "LogoSchauHin.svg" }){
          publicURL
           } ,
           buttonZurueck: file(relativePath: { eq: "ButtonZurueck.svg" }){
          publicURL
           } ,
          allStrapiQuizzes {
            edges {
              node {
                  id,
                  ShortHeadline,
                  Headline,
                  QuizImage {
                      publicURL
                    },
                  BackgroundRGB
           
              } 
            }
          }
        }
        `}

        render={d =>  {

          let onChangeSlider = (oldIndex, newIndex) => {
            this.setSliderOptions();
            this.currentSlideIndex = Math.round(newIndex+1);
            if(this.currentSlideIndex>=d.allStrapiQuizzes.edges.length) {
              this.currentSlideIndex=0
            }

            if(this.isMobile) {
              this.setState({currentBackground: d.allStrapiQuizzes.edges[this.currentSlideIndex].node.BackgroundRGB})
            }
          }

          var that = this;
          let onInit = (index) => {
            if(that.shouldChangeOrientation===true) {
              this.setSliderOptions();
              if(that.isMobile===true) {
                that.setState({currentBackground: d.allStrapiQuizzes.edges[that.currentSlideIndex].node.BackgroundRGB})
              } else {
                that.setState({currentBackground: that.defaultBackground});
              }
              that.shouldChangeOrientation = false;
            }

           }

          var settings = {
            beforeChange:onChangeSlider,
            onInit:onInit,
            onReInit:onInit,
            
            speed: 500,
            nextArrow: <SampleNextArrow 
            arrowRight={d.arrowRight.publicURL}/>,
            prevArrow: <SamplePrevArrow 
            arrowLeft={d.arrowLeft.publicURL}/>,

            slidesToShow: this.slidesToShow,
            slidesToScroll: this.slidesToScroll,
            initialSlide: this.initialSlide,
            infinite: this.infinite,
            swipeToSlide:this.swipeToSlide,
          };
          
          return (

          <div className={s.wrapper}>
          <div className={s.fullscreen} style={{backgroundColor: this.state.currentBackground}}>
          <ButtonZurueck link='/'/>
             <LogoSchauHin/>
              <h1 className={s.headline}>
                  {this.props.data.strapiTopicScreen.Headline}
              </h1>
          <div className={s.container}>
          <Slider {...settings}>
          {
            d.allStrapiQuizzes.edges.map(
              (item, index) => 
              
              <Link key={item.node.id} to={'/'+item.node.id} className={s.link}>
              <div className={`${s.item} ${index===this.currentSlideIndex ? s.bigItem : ""}`}
            
              onMouseOver={(e) => e.currentTarget.style.background = item.node.BackgroundRGB} onMouseOut={(e) => e.currentTarget.style.background = 'none'}   >
              <h2>

                 {item.node.ShortHeadline}
              </h2>
                <img className={s.icon} src={item.node.QuizImage.publicURL} alt={item.node.Headline}/>
              </div>
              </Link>
            
            )}
          
         

            </Slider>
            </div>
            </div>
        </div>
        )}
      }
      />

      )
    }
  }