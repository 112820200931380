import React from 'react'
import SliderThemen from '../components/Themen/SliderThemen'
import { Helmet } from 'react-helmet'

const Themen=({data})=>{
    return(
        <div>
          <Helmet >
          <script id="_etLoader" type="text/javascript" charset="UTF-8" data-block-cookies="true" data-respect-dnt="true" 
          data-secure-code="8bbt9s" src="//static.etracker.com/code/e.js"/>
</Helmet>
<SliderThemen data={data}/>
        </div>
    )
}
export default Themen;
export const query = graphql`
query  {
    strapiTopicScreen {
      Headline
   
    }
  }
`  


